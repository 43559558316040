<template>
  <div class="content-container">
    <PageTitle :title="$t('CLASSES')" class="page-title">
      <template #extra-content>
        <BasicButton :button-text="$t('CREATE_CLASS_TEXT')" @click="createClass()"/>
      </template>
    </PageTitle>
    <h1 class="page-section-title section-title">{{ $t('CLASSES_HELPDESK.OVERVIEW') }}</h1>

    <CustomTable
      ref="customTable"
      :options="classesTableOptions"
      :table-data="classes.items"
      :pagination-data="classes"
      :less-padding="true"
      :search-bar-options="
        {
          placeholder: $t('CLASSES_HELPDESK.SEARCH_PLACEHOLDER'),
          searchId: 'search-classes'
        }"
      :search-function="manageSearch"
      table-class="classes-table"
      :no-results-data="noResultsData">
      <template #name="props">
        <div>{{ props.rowData.name }}</div>
      </template>
      <template #schoolTypeName="props">
        <div>{{ props.rowData.schoolTypeName }}</div>
      </template>
      <template #schoolClassTypeName="props">
        <div>{{ props.rowData.schoolClassTypeName }}</div>
      </template>
      <template #studentCount="props">
        <div>{{ props.rowData.studentCount }}</div>
      </template>
      <template #createdAt="props">
        <div>{{ showJustDate( props.rowData.createdAt) }}</div>
      </template>
      <template #actions="props">
        <div class="action-button-group">
          <ActionButton :text="$t('VIEW')"
                        :icon="require('../../../assets/icons/icn_visibility.svg')"
                        custom-class="button-blue"
                        @click="viewDetails(props.rowData)"/>
        </div>
      </template>
      <template #right-side-info>
        <p>{{ classes.totalItems + " " }} {{ $t('CLASSES_HELPDESK.TOTAL_CLASSES') }} </p>
      </template>
    </CustomTable>
  </div>
</template>

<script>
import { showJustDate } from '@/utils/helpers/DateFormatter'

import PageTitle from '@/components/elements/texts/PageTitle'
import { ROUTE_NAMES_HELPDESK } from '@/router/modules/helpdesk'
import { computed, ref } from '@vue/reactivity'
import { CLASSES_TABLE, getTableOptions } from '@/utils/helpers/customTable/CustomTableHelper'
import CustomTable from '@/components/table/CustomTable'
import ActionButton from '@/components/table/ActionButton'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { SEARCH_CLASSES } from '@/store/modules/helpdesk/actions'
import { createNoResultsData } from '@/utils/helpers/customTable/EmptyTableHelper'
import BasicButton from '@/components/elements/basic/BasicButton'

export default {
  name: 'ClassesOverview',
  components: {
    ActionButton,
    CustomTable,
    PageTitle,
    BasicButton,
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const customTable = ref(null)

    /** Classes **/
    const classes = computed(() => store.getters.getClasses)


    /** Table Actions **/
    function viewDetails(clickedClass) {
      router.push({ name: ROUTE_NAMES_HELPDESK.CLASS_DETAILS, params: { classId: clickedClass.id } })
    }


    const noResultsData = computed(() => {
      if (!customTable.value) {
        return {}
      }
      return createNoResultsData('CLASSES_HELPDESK', require('@/assets/icons/icn_class.svg'), customTable.value.lastSearch)
    })


    manageSearch({})

    function searchSchool(payload) {
      return store.dispatch(SEARCH_CLASSES, payload)
    }

    //Adds in current page and sorting if required, then send of search.
    function manageSearch(payload) {
      if (!payload) {
        payload = {}
      }
      searchSchool(payload)
    }

    function createClass() {
      router.push({ name: ROUTE_NAMES_HELPDESK.CLASS_CREATE })
    }

    return {
      ROUTE_NAMES_HELPDESK,

      /** Classes **/
      classes,

      /** Table **/
      classesTableOptions: getTableOptions(CLASSES_TABLE),
      noResultsData,

      /** Table Actions **/
      viewDetails,
      manageSearch,
      showJustDate,
      createClass,

      customTable,
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.create-class {
  font-size: rem(16);
  width: rem(210);
  height: rem(41);
  padding: 0;
  line-height: rem(41);
}
</style>
